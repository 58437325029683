/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Box, Flex, BoxProps, Link } from 'rebass';

import { P, H4, Text } from '../../../components/Text';
import { AboutLayout } from '../../../layouts/AboutLayout';
import { Container } from '../../../components/Container';
import Logo from '../../../components/Logo';
import { IDESLogo } from '../../../components/IXDALogoBox/IDESLogo';
import { ISDCLogo } from '../../../components/IXDALogoBox/ISDCLogo';
import { AwardsLogo } from '../../../components/IXDALogoBox/AwardsLogo';

type AboutBandProps = {
  logo: React.ReactNode;
} & BoxProps;

const AboutBand: React.FC<AboutBandProps> = ({ logo, children, ...props }) => (
  <Box sx={{ py: [5, 6] }} {...props}>
    <Container>
      <Flex mx={-2} flexDirection={['column', 'row']}>
        <Flex px={2} mb={[4, 0]} flex={2} alignItems={['center']}>
          {logo}
        </Flex>
        <Flex px={2} flex={3} alignItems={['center']}>
          <Box>{children}</Box>
        </Flex>
      </Flex>
    </Container>
  </Box>
);

const AboutOverviewPage = () => {
  return (
    <AboutLayout>
      <Container>
        <Box
          sx={{
            width: [null, '80%'],
            mx: 'auto',
            my: [4],
            textAlign: 'center',
          }}
        >
          <P sx={{ fontSize: 2 }}>
            La Semaine d'Interaction est une série d'événements de design pour
            la communauté globale de design d'interaction.
          </P>
          <P sx={{ fontSize: 2 }}>L'édition de cette année comprend:</P>
        </Box>
      </Container>

      <AboutBand
        sx={{ bg: 'text', color: 'background' }}
        logo={
          <Logo
            type="interaction-21glitch"
            fill="background"
            sx={{ height: ['30px', '60px'], width: ['115px', '230px'] }}
          />
        }
      >
        <H4>Interaction 21</H4>
        <Text sx={{ marginTop: 3 }}>
          Notre point focal. Rejoignez les esprits les plus brillants du design
          d'interaction alors que nous examinerons les enjeux qui nous
          entourent.
        </Text>
      </AboutBand>

      <AboutBand
        sx={{ bg: 'background', color: 'text' }}
        logo={<IDESLogo fill="text" height={['50px', '100px']} />}
      >
        <H4>Sommet sur l'éducation en design d'interaction</H4>
        <Text sx={{ my: 3 }}>
          Tout en approfondissant nos apprentissages, chaque année nous
          concentrons notre attention sur l'avancement de l'éducation du design
          d'interaction à travers tous les niveaux d'avancement de carrière; de
          l'éducation en jeune âge jusqu'à la formation profesionnelle.
        </Text>
        <Link href="https://www.edusummit.ixda.org/" target="_blank">
          En savoir plus ↗
        </Link>
      </AboutBand>

      <AboutBand
        sx={{ bg: 'text', color: 'background' }}
        logo={<ISDCLogo fill="background" height={['50px', '100px']} />}
      >
        <H4>Charette de Design Étudiante</H4>
        <Text sx={{ my: 3 }}>
          La Charette de Design Étudiante amplifie les voix de la relève en
          design d'interaction. Nous existons pour promouvoir le design pour le
          bien, par de nouveaux designers. Pour se faire, nous réunissons
          étudiants et nouveaux profesionnels pour qu'ils puissent relever de
          grands défis.
        </Text>
        <Link
          href="http://sdc.ixda.org/"
          sx={{ color: 'background' }}
          target="_blank"
        >
          En savoir plus ↗
        </Link>
      </AboutBand>

      <AboutBand logo={<AwardsLogo fill="text" height={['50px', '100px']} />}>
        <H4>Gala des Prix ​​d'Interaction 2021</H4>
        <Text sx={{ my: 3 }}>
          Célébrez avec nous alors que nous célébrons les esprits les plus
          brillants en design d'interaction de par le monde. Le format du gala
          annuel des Prix d'Interaction sera révélé dans les semaines à venir.
        </Text>
        <Link href="https://awards.ixda.org/" target="_blank">
          En savoir plus ↗
        </Link>
      </AboutBand>

      {/* To remove the margin top from the footer so it's nicer. */}
      <Box mb={-5} />
    </AboutLayout>
  );
};

export default AboutOverviewPage;
